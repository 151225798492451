import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import classNames from "classnames"
import Image from "next/legacy/image"
import Link from "next/link"
import styles from "./article.module.css"

function Article({ item, className, showImage = false, showTag = true }) {
  const { meta, tag, image } = item
  const dateLine = [
    !item.meta.dateline?.showModified
      ? item.meta.dateline.created
      : item.meta.dateline.modified,
    item.meta.dateline.readTime > 0
      ? item.meta.dateline.readTime + " min att läsa"
      : false
  ]

  return (
    <div className={styles.article}>
      {showImage && image.url && (
        <Link
          href={item.url}
          prefetch={false}
          title={item.title}
          className={styles.link}>
          <figure className={styles.figure}>
            <Image
              className={styles.image}
              src={image.url}
              sizes="(max-width: 768px) 100vw, 25vw"
              alt={image.alt}
              objectPosition="center"
              objectFit="cover"
              layout="fill"
            />
          </figure>
        </Link>
      )}
      <div className={classNames(className)}>
        {tag && showTag && <HeadingLabel>{tag.label}</HeadingLabel>}
        <Link
          href={item.url}
          prefetch={false}
          title={item.title}
          className="block">
          <strong className={classNames(styles.headline, "h5")}>
            {item.title}
          </strong>
        </Link>
        <span className={styles.meta}>
          {meta.byline && <span className="block">Av: {meta.byline}</span>}
          {dateLine.filter(Boolean).join(" • ")}
        </span>
      </div>
    </div>
  )
}

export default Article
